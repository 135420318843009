@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#EnrolmentExport {
	.EnrolmentExportButton {
		@include PaddingAreas(0.5, 1);
	}

	.EnrolmentExportButtonIcon + .EnrolmentExportLabel {
		margin-left: 5px;
	}
}
