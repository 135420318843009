@import '~@studyportals/styles-abstracts/abstracts.scss';

.MultiSelectWrapper {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	@include PaddingAreas(0, 0.5);

	border-radius: 3px;
	border: 1px solid $GreyL;

	background: $White;

	cursor: pointer;

	-webkit-tap-highlight-color: transparent;

	&:focus {
		border-color: $BrandBlue;
		outline: none;
	}

	&.is-invalid {
		border: 1px solid $Red;
	}

	&.is-disabled {
		background-color: $GreyLL;
		border: 0;

		.MultiSelectIcon {
			border-top: 8px solid $Grey;
		}

		.MultiSelectLabel {
			cursor: not-allowed;
		}
	}

	.MultiSelectIcon {
		position: absolute;
		right: 1rem;
		top: 50%;

		width: 0;
		height: 0;

		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 8px solid $GreyDD;

		transform: translateY(-50%);

		transition: 0.5s all;

		&.is-active {
			transform: rotate3d(1, 0, 0, 180deg) translateY(calc(-50% + 8px));
		}
	}
}

.MultiSelectLabel {
	position: relative;

	display: flex;
	width: 100%;
	height: 45px;
	align-items: center;

	cursor: pointer;

	&.is-empty {
		color: $Grey;
	}

	.MultiSelectPlaceholder {
		position: absolute;
		left: 0.25rem;

		max-width: calc(100% - 2rem);
		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
	}

	.FloatingLabel {
		padding: 0 0.25rem;

		transition: all 0.3s;

		@include TextStyle(Body);
		color: $Grey;

		cursor: pointer;

		&.is-active {
			transform: translateY(-24px);

			@include TextStyle(Note);

			background: linear-gradient(0deg, $White 50%, transparent 50%);

			cursor: default;
		}
	}
}

.MultiSelectDropDown {
	position: absolute;
	top: calc(100% + 0.5rem);
	left: 0;
	z-index: $SecondFloor;

	min-width: 100%;
	max-height: 300px;
	overflow: auto;
	box-sizing: border-box;
	box-shadow: 0 2px 8px $GreyL;

	border-radius: 3px;

	background-color: $White;

	ol {
		list-style-type: none;
		@include MarginAreas(0);
		@include PaddingAreas(0);
	}
}

.MultiSelectSelectedOptions {
	width: 100%;
	@include PaddingAreas(0, 1.5, 0, 0);
	box-sizing: border-box;

	text-align: left;
}

.ValidationLabel,
.HelperMessage {
	padding: 0.5rem 0 0.5rem 0.75rem;

	color: $Grey;
	@include TextStyle(Note);
	text-align: left;
}

.ValidationLabel {
	color: $Red;
}
