@import '~@studyportals/styles-abstracts/abstracts.scss';

.OptionalFilterTags {
	display: none;
	vertical-align: middle;
	max-width: calc(100% - 17rem);
	max-height: 46px;
	overflow: hidden;

	&.OptionalFilterTagsAvailable {
		@include MarginAreas(0, 0, 0.5);
		display: block;
	}

	.OptionalFilterBlock {
		background-color: $GreyLL;
	}

	@media all and (max-width: 1280px) {
		max-width: calc(100% - 13.5rem);
	}
}

#ReviewOverview .OptionalFilterTags {
	display: inline-block;

	&.OptionalFilterTagsAvailable {
		margin-left: 0.6rem;
		border-left: 1px solid $GreyL;
	}
}

#IdentifiedEnrolmentsSection .OptionalFilterBlock.OptionalFiltersTag {
	max-height: 2rem;

	&:first-of-type {
		@include MarginAreas(0);
	}

	.OptionalFilterBlockTextWrapper {
		vertical-align: baseline;
	}

	.OptionalFilterBlockText {
		line-height: 1rem;
		min-height: 1rem;
	}

	.OptionalFilterBlockIcon {
		line-height: 1rem;
		vertical-align: baseline;
		font-size: 1rem;
	}
}
