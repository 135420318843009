@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import './../../../styles/_warning';

#SubmitReview {
	.SubmitReviewContainer {
		position: relative;

		.SubmitReviewNotification {
			@include speechBulbNotification(true, 14rem, -3.75rem, -9.75rem, 2.5rem);
		}

		.RequestSubmitReviewButton {
			@include PaddingAreas(0.5, 1);
			align-self: flex-end;

			span {
				margin-left: 0.5rem;
			}
		}

		.SubmitReviewNotificationCloseButton {
			@include TextStyle(Note);
			color: $White;
		}
	}
}
