@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/variables/z-index';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

.HighlightedFeatureBackdrop {
	content: ' ';
	background: rgba(5, 41, 60, 0.3);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $SecondFloor;
}

.HighlightedFeatureWrapper {
	position: relative;
}

.FeatureHighlightBlock {
	@include MarginAreas(1, 0, 0);
	@include PaddingAreas(1.5);
	z-index: $ThirdFloor;
	border: none;
	border-radius: 3px;
	font-size: 0;

	&::before {
		display: block;
		content: '';
		position: absolute;
		top: -1rem;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 1rem 0.5rem 0;
		border-color: $White transparent transparent transparent;
		transform: rotate(180deg);
	}

	.FeatureHighlightBlockTitle {
		@include TextStyle(Title100);
	}

	.FeatureHighlightBlockText {
		@include TextStyle(Note);
		@include MarginAreas(0, 2, 0, 0);
	}

	.PreviousFeatureButton,
	.NextFeatureButton {
		@include TextStyle(Note);
		color: $GreyD;
		text-decoration: none;
		line-height: 2.25rem;

		&:hover,
		&:active {
			color: $GreyD;
			text-decoration: none;
		}
	}

	.PreviousFeatureButton {
		float: left;

		&:before {
			@include MarginAreas(0, 0.5, 0, 0.25);
			content: '<';
		}
	}

	.NextFeatureButton {
		float: right;

		&:after {
			@include MarginAreas(0, 0.25, 0, 0.5);
			content: '>';
		}
	}

	.FinalFeatureButton {
		@include PaddingAreas(0, 1);
		float: right;
		min-height: 2.25rem;
	}

	.NonFinalFeatureButton {
		padding-top: 1.25rem;
		line-height: 1rem;
	}

	.HighlightedFeatureCloseButton {
		@include MarginAreas(0);
		@include PaddingAreas(1);
		position: absolute;
		box-sizing: border-box;
		background: none;
		cursor: pointer;
		text-align: center;
		font-size: 1.5rem;
		border: 0;
		right: 0;
		top: 0;
	}

	.FeatureHighlightBlockIcon {
		@include MarginAreas(0, 0.5, 0, 0);
		display: inline-block;
		vertical-align: top;
		font-size: 1.5rem;
		text-align: center;
		width: 2rem;
		color: $Yellow;
	}

	.FeatureHighlightBlockContentWrapper {
		display: inline-block;
		width: calc(100% - 2.5rem);
	}
}
