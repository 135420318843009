@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.EnrolmentReviewEntry {
	background-color: transparent;

	.Review {
		display: flex;
		justify-content: center;
		height: 2rem;
		padding-top: 7px;
	}

	.EditReview {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 2rem;
		padding: 4px 0;

		.EditReviewActions {
			display: flex;
			justify-content: flex-end;
			margin-top: 0.75rem;

			.NoteChallengedBack {
				color: $Yellow;
				cursor: pointer;

				&:hover {
					color: $BrandOrangeD;
				}
			}
		}
	}

	.ReviewEnrolment {
		@include PaddingAreas(0, 1);
		min-height: 2rem;
	}
}
