@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/variables/_z-index';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .MassEditModalWrapper.ModalWrapper.ComplexModal {
	width: 22rem;
	height: auto;
	overflow: visible;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;
		z-index: $FirstFloor;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
		overflow: visible;
	}
}

#MassEditModal {
	/* This is necessary for the multiselect dropdown to break out of the parent with overflow auto. */
	perspective: 0;

	.MassEditModalHeaderBlock {
		@include PaddingAreas(1, 1.5);
		background-color: $BrandBlue;
	}

	.MassEditModalContent {
		@include PaddingAreas(1, 1.5);
	}

	.MassEditModalHeaderTitle {
		@include TextStyle(Highlight);
		@include MarginAreas(0);
		color: $White;
	}

	.MassEditModalSubtitle {
		@include TextStyle(Title100);
		@include MarginAreas(0, 0, 1);
	}

	.MassEditLabel {
		@include TextStyle(Title100);
		@include MarginAreas(0, 0, 0.5);
		display: block;
	}

	.MassEditCheckboxLabel {
		@include PaddingAreas(0, 0, 0.5);
		@include TextStyle(Note);
		display: block;

		.MassEditCheckbox {
			@include MarginAreas(0, 0.5, 0, 0);
			vertical-align: middle;
		}
	}

	.MassEditInput {
		@include TextStyle(Body);
		padding: 0.25rem 0.75rem;
		border: 1px solid $GreyLL;
		border-radius: 3px;
		box-sizing: border-box;
		color: $GreyDD;
		height: 3rem;
		width: 100%;
		outline: none;
		text-overflow: ellipsis;

		&:hover,
		&:active {
			border: 1px solid $GreyL;
		}
	}

	.MassEditModifyBlock {
		@include MarginAreas(0, 0, 1);
		@include PaddingAreas(1);
		border: 1px solid $GreyLL;
		max-height: calc(70vh - 10rem);
		overflow-y: auto;

		.MultiSelectDropDown {
			/* This is necessary for this dropdown to break out of the parent with overflow auto. */
			position: fixed;
			left: 2.5rem;
		}
	}

	.MassEditSection {
		display: block;

		& + .MassEditSection {
			@include MarginAreas(1, 0, 0);
		}

		.MassEditErrorMessage {
			@include TextStyle(Note);
			display: block;
			padding: 0.5rem 0 0.5rem 0.75rem;
			color: $Red;
		}

		&.WarningContainer {
			@include PaddingAreas(1);
			@include MarginAreas(1, 0, 0);
			display: block;
			background-color: $GreyLL;
			font-size: 0;

			.WarningIcon {
				@include MarginAreas(0, 0.5, 0, 0);
				color: $Grey;
				display: inline-block;
				width: 1.5rem;
				font-size: 1.25rem;
				vertical-align: middle;
			}

			.WarningMessage {
				@include TextStyle(Note);
				display: inline-block;
				width: calc(100% - 2rem);
				vertical-align: middle;
			}

			strong {
				font-weight: 700;
			}
		}

		&.MassEditSectionButtons {
			text-align: right;
		}

		.MassEditButtonBack {
			@include PaddingAreas(0, 1, 0, 0.5);
			@include MarginAreas(0.5, 0);
			@include TextStyle(BodyNarrow);
			float: left;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-height: 2rem;
			height: auto;
			background: transparent;
			border: 0;
			border-radius: 3px;
			color: $GreyDD;
			cursor: pointer;
			transition: all 0.15s linear;

			&:before {
				@include MarginAreas(0, 0.5, 0, 0);
				font-family: 'icomoon';
				content: '\e943';
			}

			&:visited {
				color: $GreyDD;
			}

			&:hover {
				background: rgba($GreyDD, 0.1);
				text-decoration: none;

				@media (hover: none) {
					background: transparent;
				}
			}
		}
	}
}
