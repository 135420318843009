@import '~@studyportals/styles-components/components/ClickableText';
@import '~@studyportals/styles-components/components/Button';
@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

.DriverButton {
	&.Green {
		background: $Green;
		border-color: $Green;
		color: $White;

		&:visited {
			color: $White;
		}

		&:hover {
			background: darkgreen;
			border-color: darkgreen;
			color: $White;

			@media (hover: none) {
				background: darkgreen;
				border-color: darkgreen;
				color: $White;
			}
		}
	}
}

.ExitButton {
	border: none;
	background: transparent;
	cursor: pointer;

	&.CancelButton {
		@include TextStyle(Body);
	}

	&.TransitionBackButton {
		display: block;
	}
}

.ButtonIcon {
	@include MarginAreas(0, 0.5, 0, 0);
}

.NotificationCloseButton {
	@include TextStyle(Highlight);
	@include PaddingAreas(0.5, 0.5, 1, 1);
	right: 0;
	top: 0;
	border: none;
	background: transparent;
	position: absolute;
	outline: none;
	cursor: pointer;
}
