/**
On UniAdmin, a limited space is available because of other elements that are
taking up space. How much space exactly should be reserved is dependant on the
user (client or internal), the page (review or history) and whether or not a
notification is visible.
 */

@mixin leaveSpaceForPageElements($user, $page, $notification, $filterBar, $sessionExpirationMessage) {
	/* Default is [1] a client visiting [2] the review page [3] without a notification, [4] no filter, and [5] no expiration message. */
	$headerHeight: 58;
	$navHeight: 51;
	$actionsHeight: 144;
	$notificationHeight: 0;
	$filterBarHeight: 0;
	$sessionExpirationMessageHeight: 0;

	@if $user == 'internal' {
		$headerHeight: 64;
		$navHeight: 47;
		$actionsHeight: 108;

		@if $filterBar {
			$filterBarHeight: 40;
		}
	}

	@if $page == 'history' {
		@if $user == 'client' {
			$actionsHeight: 118;
		} @else {
			$actionsHeight: 141;
		}
	}

	@if $notification {
		$notificationHeight: 40;
	}

	@if $sessionExpirationMessage {
		$sessionExpirationMessageHeight: 60;
	}

	/* eslint-disable-next-line max-len */
	height: calc(
		100vh - #{$headerHeight}px - #{$navHeight}px - #{$actionsHeight}px - #{$notificationHeight}px - #{$filterBarHeight}px - #{$sessionExpirationMessageHeight}px
	);
}
