@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import './../../../styles/_page.scss';
@import '../../generalizations/custom-table-components/edit-enrolment-entry/edit-enrolment-entry.scss';
@import '../../generalizations/custom-table-components/enrolment-confirmation-status/enrolment-confirmation-status.scss';

.EnrolmentsOverview {
	#EnrolmentsTableComponent .EnrolmentsTable {
		@include leaveSpaceForPageElements('internal', 'review', false, false, false);
	}

	.OptionalFilterTagsAvailable + #EnrolmentsTableComponent .EnrolmentsTable {
		@include leaveSpaceForPageElements('internal', 'review', false, true, false);
	}
}

[data-wrapper-visible='true'] + div + .FullWidthContent .EnrolmentsOverview {
	#EnrolmentsTableComponent .EnrolmentsTable {
		@include leaveSpaceForPageElements('internal', 'review', false, false, true);
	}

	.OptionalFilterTagsAvailable + #EnrolmentsTableComponent .EnrolmentsTable {
		@include leaveSpaceForPageElements('internal', 'review', false, true, true);
	}
}

#EnrolmentsTableComponent {
	.EnrolmentsTable {
		.TableHeaderCell {
			@include TextStyle(Body);
		}

		.TableActionCell {
			padding-top: 3px;
		}

		.TableContentCell {
			@include TextStyle(Note);
			padding-top: 1rem;
		}

		.TableCell,
		.TableHeaderCell {
			border-right: 0.5px solid $GreyLL;
		}

		.ag-header {
			box-shadow: 0 5px 4px -4px $GreyLL;
		}

		.ag-pinned-left-header {
			box-shadow: 2px 2px 4px -1px $GreyLL;
		}

		.ag-pinned-left-cols-container {
			box-shadow: 5px 0 4px -4px $GreyLL;
		}
	}
}
