@import '~@studyportals/styles-abstracts/abstracts.scss';

.OptionalFilterBackdrop {
	content: ' ';
	background: transparent;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $SecondFloor;
	cursor: pointer;
}

.OptionalFiltersPanel {
	position: absolute;
	display: flex;
	box-sizing: border-box;
	top: 0;
	left: 4.75rem;
	z-index: $ThirdFloor;
	background-color: $White;
	border-radius: 3px;
	box-shadow: 0 2px 8px $GreyL;

	.OptionalFiltersSelectionPanel {
		box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
	}

	.ApplyFiltersButtonWrapper {
		@include MarginAreas(0.5, 0);
		text-align: center;
	}

	.OptionalFilterSelectionBlock {
		padding: 0.75rem 1rem;
		border-bottom: 1px solid $GreyL;
		display: flex;
		width: 16rem;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		label {
			cursor: pointer;
			vertical-align: middle;
		}

		&.OptionalFilterSelectionBlockSelected {
			@include TextStyle(Title100);
			background-color: $GreyLL;
			color: $BrandBlue;
		}

		.OptionalFilterSelectionBlockDetailsIcon {
			@include MarginAreas(0, 0.5, 0, 0);
			font-size: 1.5rem;
			vertical-align: middle;
		}

		.OptionalFilterSelectedMarker {
			@include TextStyle(Note);
			display: inline-block;
			background-color: $Grey;
			border-radius: 50%;
			color: $White;
			width: 1rem;
			text-align: center;
			vertical-align: middle;
		}

		.OptionalFilterSelectionBlockChevron {
			@include MarginAreas(0, 0, 0, 0.5);
			vertical-align: middle;
		}
	}

	.OptionalFiltersContentPanel {
		@include PaddingAreas(1);
		width: 22rem;

		.OptionalFiltersContentDescription {
			@include TextStyle(Title100);
			@include MarginAreas(0, 0, 1);
		}

		.HiddenOptionalFilter {
			display: none;
		}
	}
}

#IdentifiedEnrolmentsSection .OptionalFiltersPanel {
	top: 3.5rem;
	left: -33.9rem;
}
