@import '~@studyportals/styles-abstracts/abstracts';

#HistoryTableFilter {
	@include TextStyle(Body);
	display: inline-block;

	#NotInvoicableFilter {
		vertical-align: middle;
		margin-right: 0.5rem;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 1px;
		height: 1.5rem;
		background-color: $GreyL;
		vertical-align: middle;
		margin-right: 0.75rem;
	}
}
