@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '../../../styles/warning';

.EnrolmentMenu {
	@include MarginAreas(0, 0, 0.5);
	display: flex;
	justify-content: space-between;
	align-items: center;

	.EnrolmentActionsWrapper {
		display: flex;
		position: relative;
		align-items: center;

		.CannotExportExplanationContainer {
			@include speechBulbNotification(false, 15rem, -4.5rem, 0.5rem, 5rem);
		}
	}
}
