@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#MoveEnrolmentsToHistory {
	.MoveEnrolmentsTitle {
		@include TextStyle(Note);
		@include MarginAreas(1, 0, 0.5);
		color: $GreyD;
		text-align: left;
	}

	.MoveEnrolmentsButton {
		width: 16rem;
	}

	.MoveEnrolmentsContainer {
		@include TextStyle(Body);
		@include PaddingAreas(0, 0.5);
		@include MarginAreas(1, 0, 0);
		text-align: left;

		.OrganisationName {
			@include TextStyle(Title100);
		}

		.MoveEnrolmentsDestination {
			@include MarginAreas(0, 0, 1);
			display: block;
		}

		.MoveToHistoryButtonContainer {
			display: flex;
			justify-content: flex-end;

			button {
				@include MarginAreas(0, 0, 0, 1);
			}
		}
	}
}
