@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';

.ModalOverlay .SubmitReviewModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: auto;
	max-width: 20rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.SubmitReviewModal {
	.SubmitReviewModalHeaderBlock {
		@include PaddingAreas(1);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Body);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.SubmitReviewModalContentBlock {
		@include PaddingAreas(1);
		margin: 0 5px;

		.SubmitReviewModalButtons {
			.DriverButton,
			.NavigatorButton {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}

	&.SubmitReviewConfirmationModal {
		.SubmitReviewConfirmationIcon {
			@include MarginAreas(0, 0, 1);
			text-align: center;
			font-size: 36px;
		}

		.SubmitReviewConfirmationText {
			@include TextStyle(Body);
		}

		.SubmitReviewConfirmationNote {
			color: $GreyD;
			margin: 5px;
			display: flex;

			.SubmitReviewConfirmationNoteIcon {
				@include TextStyle(Note);
				margin-right: 5px;
			}

			.SubmitReviewConfirmationNoteText {
				@include TextStyle(Note);
				@include MarginAreas(0, 0, 0.5);
			}
		}

		.SubmitReviewModalButtons {
			@include MarginAreas(1, 0, 0);
		}
	}

	&.SubmitReviewLaterModal {
		.SubmitReviewLaterModalImage {
			display: block;
			height: 6.5rem;
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
		}

		.SubmitReviewLaterModalText {
			@include TextStyle(Body);

			margin-top: 30px;
		}

		.SubmitReviewModalButtons {
			margin-top: 127px;
		}
	}
}
