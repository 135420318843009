@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';

.PartnershipSelectorOption {
	display: flex;

	background-color: $White;

	padding: 1rem;

	border-top: 1px solid $GreyL;
	border-left: 1px solid $GreyL;
	border-right: 1px solid $GreyL;

	cursor: pointer;

	.PartnershipLabel {
		@include TextStyle(Body);
		margin-left: 1rem;
		min-width: 12rem;
	}

	.SelectionIndicator {
		color: $Yellow;

		&.Selected {
			color: $Green;
		}
	}
}
