@import './../../../styles/main';
@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#ClientEnrolmentsOverview {
	background: $GreyLLL;

	.EnrolmentOverviewActionsContainer {
		display: flex;
		align-items: center;

		#SubmitReview {
			margin-left: auto;
			margin-right: 0.5rem;
		}

		#EnrolmentsTableFilter {
			margin-top: auto;
		}
	}

	.EnrolmentsHeaderWrapper {
		display: flex;
	}

	.EnrolmentsOverviewHeader {
		display: flex;
		align-items: center;
		flex-grow: 1;

		.PartnershipSelector {
			flex: auto;
			margin-left: 1rem;
		}

		.Title {
			@include TextStyle(Title200);
			@include MarginAreas(0, 0.5, 0, 0);
			line-height: 2.75rem;
			font-weight: 400;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 1;
			height: 2.75rem;
			max-height: 2.75rem;
		}
	}
}

#ReviewOverview .EnrolmentsOverviewHeader {
	max-width: calc(100% - 32rem);
}
