@mixin positionHighlightedFeature($blockWidth, $positionBlockFromLeft, $blockOffset, $positionArrowFromLeft, $arrowOffset) {
	width: $blockWidth;

	@if $positionBlockFromLeft {
		left: $blockOffset;
	} @else {
		right: $blockOffset;
	}

	&::before {
		@if $positionArrowFromLeft {
			left: $arrowOffset;
		} @else {
			right: $arrowOffset;
		}
	}
}
