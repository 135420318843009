@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

#TransitionTriggers {
	.TransitionBtn {
		@include PaddingAreas(0.5, 1);
		display: inline-block;
		margin-bottom: 0.5rem;
		width: 17rem;
	}

	.TransitionTitle {
		@include TextStyle(Note);
		@include MarginAreas(1, 0, 0.5);
		text-align: left;
		color: $GreyD;
	}

	.Arrow {
		line-height: 1.5rem;

		&.Left {
			float: left;
		}

		&.Right {
			float: right;
		}
	}
}
