@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';

.ReviewStatusIcon {
	@include TextStyle(Body);
	padding-top: 4px;

	.ReviewedIcon,
	.SubmittedIcon {
		color: $Green;
	}

	.CheckIcon {
		color: $Yellow;
	}

	.approvedIcon {
		color: $Green;
	}

	.isFinalIcon {
		color: $Grey;
	}
}
