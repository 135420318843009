@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

$IntroVisualWidth: 40%;

.ClientIntroductionContentWrapper {
	display: none;
}

.ModalOverlay .ClientIntroductionModalWrapper.ModalWrapper.ComplexModal {
	width: 64rem;
	height: auto;
	min-height: 36rem;
	overflow-y: auto;

	.ContentWrapper {
		@include PaddingAreas(1);
		border-top: 1rem solid $BrandBlue;
	}

	.CloseButton {
		top: 1rem;
	}
}

.ClientIntroductionContent {
	@include PaddingAreas(0, 3);

	.ClientIntroductionTitle {
		@include TextStyle(Title200);
		@include MarginAreas(0);
		text-align: center;

		.ClientIntroductionTitleContent {
			@include PaddingAreas(0, 0, 1);
			display: block;

			&.TitleAboveTerms {
				border-bottom: 1px solid $GreyL;
			}
		}
	}

	.ClientIntroductionIntro {
		@include MarginAreas(1.5, 0, 1);
		font-size: 0;

		.IntroMessage {
			@include MarginAreas(0, 3, 0, 0);
			/* Leave enough room for the DPA image and right margin. */
			width: calc(100% - #{$IntroVisualWidth} - 3rem);
			display: inline-block;
			vertical-align: middle;

			.IntroTitle {
				@include TextStyle(Title100);
				@include MarginAreas(0, 0, 0.5);
			}

			.IntroText {
				@include TextStyle(Body);
			}
		}

		.IntroVisual {
			display: inline-block;
			vertical-align: middle;
			width: $IntroVisualWidth;

			&.IntroVisualImage {
				@include PaddingAreas(0, 4);
			}
		}
	}

	.ClientIntroductionTerms {
		@include PaddingAreas(1);
		max-height: 13rem;
		border: 1px solid $GreyL;
		overflow-y: auto;

		.TermsList {
			@include PaddingAreas(0, 0, 0, 1.5);
		}

		.TermsTitle {
			@include TextStyle(Highlight);
		}

		.TermsSubTitle {
			@include TextStyle(Body);
			color: $GreyD;
		}

		.TermsText {
			@include TextStyle(Note);
			color: $GreyD;

			li {
				margin-top: 1rem;
				@include MarginAreas(1, 0, 0);
				@include PaddingAreas(0, 0, 0, 1);
			}
		}
	}

	.BiggerVideoWrapper {
		@include MarginAreas(0, 3, 1.5);

		.IntroText {
			@include MarginAreas(0);
		}
	}

	.AcceptButton {
		display: block;
		margin: 1.5rem auto 1rem;
	}
}
