@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

.HighlightedFeaturesIntroductionContentWrapper {
	display: none;
}

.ModalOverlay .HighlightedFeaturesIntroductionModalWrapper.ModalWrapper.ComplexModal {
	width: 46rem;
	height: auto;
	min-height: 15rem;
	overflow-y: auto;

	.ContentWrapper {
		@include PaddingAreas(1.5);
	}

	.HighlightedFeaturesIntroductionContent {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.HighlightedFeaturesIntroductionModalButton {
			@include MarginAreas(1, 0, 0);
		}
	}

	.HighlightedFeaturesIntroductionModalTextWrapper {
		width: 26rem;
		text-align: center;

		.HighlightedFeaturesIntroductionModalTitle {
			@include TextStyle(Title200);
		}

		.HighlightedFeaturesIntroductionModalSubtitle {
			@include TextStyle(Title100);
		}

		.HighlightedFeaturesIntroductionModalText {
			@include TextStyle(BodyNarrow);
		}
	}

	.CloseButton {
		font-size: 1.5rem;
	}
}
