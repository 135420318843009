@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#MoveEnrolmentsToOrganisation {
	.MoveEnrolmentsTitle {
		@include TextStyle(Note);
		@include MarginAreas(1, 0, 0.5);
		color: $GreyD;
		text-align: left;
	}

	.MoveEnrolmentsButton {
		width: 16rem;
	}

	.MoveEnrolmentsContainer {
		@include TextStyle(Body);
		@include PaddingAreas(0, 0.5);
		@include MarginAreas(1, 0, 0);
		text-align: left;
		width: 19rem;

		.OrganisationName {
			@include TextStyle(Title100);
		}

		.ButtonsContainer {
			display: flex;
			justify-content: flex-end;

			button {
				@include MarginAreas(0, 0, 0, 1);
			}
		}
	}

	.SearchContainer {
		@include PaddingAreas(0.5, 0);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 2px solid $GreyD;

		.SearchIcon {
			color: $GreyD;
			flex-grow: 1;
		}

		.SearchInput {
			border: none;
			flex-grow: 3;
		}

		.SearchClear {
			color: $GreyD;
			font-size: 1.1rem;
		}
	}

	.SearchResult {
		@include TextStyle(Body);
		@include PaddingAreas(0.5);
		width: 18rem;
		background: $White;
		border: 1px solid $Grey;
		text-align: left;
		position: absolute;

		.OrganisationID {
			@include MarginAreas(0, 0, 0, 0.5);
			display: inline-block;
			color: $Grey;
		}
	}
}
