@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import './../../../styles/_page.scss';
@import '../../generalizations/custom-table-components/country-icon/country-icon.scss';
@import '../../generalizations/custom-table-components/review-status-indication/review-status-indication.scss';
@import '../../generalizations/custom-table-components/enrolment-review-entry/enrolment-review-entry.scss';

.EnrolmentsOverview {
	#ReviewOverview #ClientEnrolmentsTableComponent {
		@include leaveSpaceForPageElements('client', 'review', false, false, false);
	}

	&.AccommodateNotification #ReviewOverview #ClientEnrolmentsTableComponent {
		@include leaveSpaceForPageElements('client', 'review', true, false, false);
	}

	#EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('client', 'history', false, false, false);
	}

	&.AccommodateNotification #EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('client', 'history', true, false, false);
	}

	#InternalHistoryOverview #EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('internal', 'history', false, false, false);
	}
}

[data-wrapper-visible='true'] + div + .FullWidthContent .EnrolmentsOverview {
	#ReviewOverview #ClientEnrolmentsTableComponent {
		@include leaveSpaceForPageElements('client', 'review', false, false, true);
	}

	&.AccommodateNotification #ReviewOverview #ClientEnrolmentsTableComponent {
		@include leaveSpaceForPageElements('client', 'review', true, false, true);
	}

	#EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('client', 'history', false, false, true);
	}

	&.AccommodateNotification #EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('client', 'history', true, false, true);
	}

	#InternalHistoryOverview #EnrolmentsHistorySection {
		@include leaveSpaceForPageElements('internal', 'history', false, false, true);
	}
}

#ClientEnrolmentsTableComponent {
	border-top: 1px solid $GreyLLL;

	.EnrolmentsTable {
		height: 100%;

		.TableHeaderCell {
			@include TextStyle(Body);
		}

		.TableContentCell {
			@include TextStyle(Note);
			padding-top: 1rem;
		}

		.TableCell,
		.TableHeaderCell {
			border-right: 0.5px solid $GreyLL;
		}

		.ag-header {
			box-shadow: 0 5px 4px -4px $GreyLL;
		}

		.ag-pinned-left-header {
			box-shadow: 2px 2px 4px -1px $GreyLL;
		}

		.ag-pinned-left-cols-container {
			box-shadow: 5px 0 4px -4px $GreyLL;
		}
	}
}
