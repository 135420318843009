@import '~@studyportals/styles-abstracts/variables/_z-index';
@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.WarningContainer {
	@include TextStyle(Note);
	background-color: $YellowLL;
	padding: 0.7rem;
	display: flex;
	align-items: center;

	.WarningIcon {
		color: $Yellow;
		font-size: 1rem;
		vertical-align: sub;
		margin-right: 10px;
	}
}

.NotAvailableWarning {
	@include MarginAreas(0, 1.5);
	@include PaddingAreas(1);
	display: flex;
	flex-direction: column;
	border: 1px dashed $Grey;

	.WarningIcon {
		margin-bottom: 1rem;
		font-size: 1.5rem;
		color: $GreyD;
	}

	.WarningText {
		@include TextStyle(Note);
		text-align: left;
		color: $GreyD;

		&:last-of-type {
			margin: 0;
		}
	}
}

@mixin speechBulbNotification($alignFromLeft, $width, $top, $horizontal, $arrowRight) {
	@include TextStyle(Note);
	position: absolute;
	z-index: $FirstFloor;
	color: $White;
	background-color: $GreyDD;
	border-radius: 0.5rem;
	text-align: left;
	padding: 0.5rem 0.75rem;
	width: $width;
	top: $top;
	@if $alignFromLeft == true {
		left: $horizontal;
	} @else {
		right: $horizontal;
	}

	&::before {
		display: block;
		content: '';

		position: absolute;
		right: calc(#{$arrowRight} - 1px);
		bottom: -0.5rem;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0.5rem 0.25rem 0;
		border-color: $GreyDD transparent transparent transparent;
	}
}
