@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/variables/_z-index.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.MassSelectionOverview {
	position: absolute;
	display: none;
	height: 0;
	width: 100%;
	top: 100px;
	left: 0;
	z-index: $ThirdFloor;
	-webkit-animation: FadeIn 0.5s;
	animation: FadeIn 0.5s;

	&.isVisible {
		display: block;
	}

	.Header {
		&.PreventContentSlideUp + .Content {
			display: none;
		}

		&.ContentHidden + .Content {
			animation: SlideUp 300ms linear;
			animation-fill-mode: forwards;
		}

		.TopLine {
			display: none;
			height: 1rem;
			width: auto;
			background-color: $BrandBlue;
			color: $White;
			box-shadow: 0 0.17rem 0.3rem 0 $GreyL;
		}

		.Title {
			@include TextStyle(Body);
			position: absolute;
			width: 350px;
			left: 50%;
			margin: auto;
			margin-top: -0.2rem;
			margin-left: -175px;
			padding-top: 0.5rem;
			padding-bottom: 0.6rem;
			background-color: $BrandBlue;
			color: $White;
			text-align: center;
			cursor: pointer;

			.WarningIcon {
				vertical-align: text-bottom;
				font-size: 1.3rem;
				margin-right: 0.3rem;
			}

			.SelectionCount {
				padding: 0.2rem 0.5rem;
				background-color: $White;
				color: $BrandBlue;
				font-weight: 600;
			}

			.IconNoteChevronDown {
				@include PaddingAreas(0, 0, 0, 0.5);
				display: none;
				vertical-align: middle;
			}

			.IconNoteChevronUp {
				@include PaddingAreas(0, 0, 0, 0.5);
				display: inline-block;
				vertical-align: middle;
			}
		}

		&.ContentHidden {
			.Title {
				border-radius: 3px;

				.IconNoteChevronDown {
					display: inline-block;
				}

				.IconNoteChevronUp {
					display: none;
				}
			}
		}
	}

	.Content {
		width: 350px;
		margin: 2.2rem auto 0;
		overflow: hidden;
		background-color: $White;
		box-shadow: 0 0.08rem 0.3rem 0 $Grey;
		animation: SlideDown 300ms linear;
		animation-fill-mode: forwards;

		.ContentContainer {
			@include PaddingAreas(1);
		}

		.SelectionHelpersWrapper {
			display: flex;
			justify-content: space-between;
		}

		.ClearSelectionIcon {
			@include TextStyle(Note);
		}

		.ClearSelection {
			@include TextStyle(Body);
			color: $GreyD;
		}

		.ButtonContainer {
			@include PaddingAreas(0, 0, 1);
			text-align: center;
		}
	}
}
