@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.StudentImpactVideoCompleteWrapper {
	@include MarginAreas(0, 0, 1);
	display: block;

	/* Styling below taken from Wistia.com, which is used to host the videos and provide embed snippets. */

	.StudentImpactVideoPositioningWrapper {
		position: relative;
		padding: 56.25% 0 0;
	}

	.StudentImpactVideoInBetweenWrapper {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}

	.StudentImpactVideoInnerWrapper {
		position: relative;
		height: 100%;
		width: 100%;
	}

	.StudentImpactVideoSwatch {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0;
		overflow: hidden;
		transition: opacity 200ms;
	}

	.StudentImpactVideoImage {
		height: 100%;
		width: 100%;
		filter: blur(5px);
		object-fit: contain;
	}
}
