@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';

.ConfirmationStatus {
	.NotConfirmed {
		color: $GreyD;
		font-style: italic;
	}

	.EnrolledLabel {
		@include TextStyle(Note);
		@include MarginAreas(0, 0.5);
		border-radius: 9px;
		background-color: $GreyLL;
		padding: 1px 7px;

		&.Regular {
			background-color: $GreenL;
			border: 1px solid $GreenL;
		}

		&.ReducedFee {
			background-color: $GreyL;
			border: 1px solid $GreyL;
		}

		&.Other {
			color: $GreyD;
			background-color: $White;
			border: 1px solid $GreyL;
		}
	}
}
