@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.HistoryOverview {
	background-color: $White;

	.EnrolmentOverviewActionsContainer {
		padding: 1.5rem;
		background: $White;
		border-bottom: 1px solid $GreyLLL;
		font-size: 0;
	}

	.ActionsContainerRow {
		@include MarginAreas(0, 0, 1);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

#ClientEnrolmentsOverview .HistoryOverview {
	.EnrolmentOverviewActionsContainer {
		@include PaddingAreas(1, 1.5, 1, 1);
	}

	.ActionsContainerRow {
		@include PaddingAreas(1, 1, 0.5);
		@include MarginAreas(0);

		& + .ActionsContainerRow {
			@include PaddingAreas(0);
			display: block;
			border-bottom: 1px solid $GreyLLL;
		}
	}
}
