@import '~@studyportals/styles-abstracts/abstracts';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.HistoryExport {
	.EnrolmentExportButton {
		@include MarginAreas(0, 2, 0);
		@include PaddingAreas(0);
		width: auto;
		border: 0;
		color: $GreyD;
		font-weight: normal;
	}
}
