@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';

.EditEnrolmentButton {
	@include TextStyle(Body);
	color: $GreyD;
	border: none;
	background: transparent;
	cursor: pointer;

	display: block;
	width: 100%;
	height: 100%;
	text-align: center;

	&:hover {
		color: $BrandBlueL;
	}

	&:focus {
		color: $BrandBlueL;
		outline: none;
	}
}
