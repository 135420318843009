@import '~@studyportals/styles-abstracts/abstracts';

.PickerContainer {
	width: 100%;
	position: relative;

	.MonthSelect {
		position: absolute;
		left: 1rem;
		bottom: 0;
		font-size: 1rem;
	}

	
	.InputContainer {
		position: relative;
		z-index: $FirstFloor;
		cursor: pointer;

		.InputIcon {
			position: absolute;
			top: 43%;
			left: 1rem;
		}

		.PickerInput {
			width: 100%;
			min-width: 245px;
			font-size: 1rem;
			line-height: 1.75rem;
			font-weight: 400;
			border: 1px solid $Grey;
			border-radius: 5px;
			box-sizing: border-box;
			color: $GreyDD;
			height: 2.5rem;
			@include MarginAreas(0.5, 0, 0, 0);
			outline: none;
			padding: 0 1rem 0 2.5rem;
			text-overflow: ellipsis;
			cursor: pointer;
		}
	
	}

	.ErrorMessage {
		display: block;
		line-height: 2rem;
		@include TextStyle(Note);
		color: $Red;
	}
}

.is-invalid {

	.MonthSelect {
		bottom: 1rem;
	}

	.PickerInput {
		border-color: $Red;
	}
}
