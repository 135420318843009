@import '~@studyportals/styles-abstracts/abstracts.scss';
@import './../../../client/highlighted-feature/highlighted-feature-positioning';

.HighlightedFeatureFilter {
	@include positionHighlightedFeature(40rem, true, 1rem, true, 2.5rem);
}

.OptionalFiltersWrapper {
	position: relative;
	box-sizing: border-box;
	display: inline-block;
}

.OptionalFilterBlock {
	@include MarginAreas(0, 0, 0, 0.5);
	@include PaddingAreas(0.5);
	font-size: 0;
	color: $GreyDD;
	background-color: $White;
	border: 1px solid $GreyL;
	border-radius: 3px;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;

	.OptionalFilterBlockTextWrapper {
		display: inline-block;
		max-width: 6rem;
		vertical-align: middle;
		text-align: left;
	}

	.OptionalFilterBlockText {
		@include TextStyle(Body);
		@include PaddingAreas(0, 1, 0, 0);
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		word-break: break-all;
		-webkit-line-clamp: 1;
		min-height: 1.75rem;
		line-height: 1.75rem;
	}

	.OptionalFilterBlockIcon {
		@include TextStyle(Highlight);
		vertical-align: middle;
		height: 1.75rem;
		line-height: 1.75rem;
	}

	&.NarrowBlock {
		@include PaddingAreas(0.5);
		width: 3rem;
		text-align: center;

		.OptionalFilterBlockIcon {
			width: 2rem;
			display: block;
		}
	}

	@media all and (max-width: 1280px) {
		.OptionalFilterBlockTextWrapper {
			max-width: 4.5rem;
		}

		.OptionalFilterBlockText {
			@include TextStyle(Note);
			@include PaddingAreas(0, 0.5, 0, 0);
			line-height: 1.75rem;
		}

		.OptionalFilterBlockIcon {
			font-size: 0.75rem;
		}
	}
}

.OptionalFilterSection {
	@include PaddingAreas(1, 0);
	border-top: 1px solid $GreyLL;

	h3 {
		@include TextStyle(Title100);
	}

	&:first-of-type {
		@include PaddingAreas(0, 0, 1);
		border-top: 0;
	}
}

#ReviewOverview .OptionalFiltersWrapper {
	width: 100%;
}

#IdentifiedEnrolmentsSection .OptionalFilterBlock .OptionalFilterBlockTextWrapper {
	max-width: 10rem;

	@media all and (max-width: 1280px) {
		max-width: 10rem;
	}
}
