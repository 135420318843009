@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/variables/_z-index';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import './../../../styles/_page.scss';

@mixin speechBulbNotification($backgroundcolor) {
	@include TextStyle(Note);
	position: absolute;
	z-index: $FirstFloor;
	color: $White;
	background-color: $backgroundcolor;
	border-radius: 0.5rem;
	text-align: left;
	padding: 0.75rem;

	&::before {
		display: block;
		content: '';

		position: absolute;
		right: 5rem;
		bottom: -0.5rem;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0.5rem 0.25rem 0;
		border-color: Black transparent transparent transparent;
	}

	&::after {
		display: block;
		content: '';

		position: absolute;
		right: 5rem;
		bottom: -0.35rem;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0.5rem 0.25rem 0;
		border-color: $backgroundcolor transparent transparent transparent;
	}
}

.ClippyWidget {
	position: absolute;
	bottom: 6rem;
	right: 8rem;
	transition: right 0.1s;

	&.ClippyVisible {
		visibility: visible;
	}

	&.ClippyHidden {
		visibility: hidden;
	}

	&.ClippyRight {
		right: 8rem;
	}

	&.ClippyLeft {
		right: 14rem;
	}

	.ClippyContentWrapper {
		position: relative;
		width: 300px;
		height: 400px;

		.ClippyTextbox {
			position: absolute;
			background-color: #feffc6;
			border: 1px solid black;
			border-radius: 10px;
			width: 300px;
			height: 150px;

			@include speechBulbNotification(#feffc6);

			.ClippyJokeText {
				color: black;
			}

			.ClippyJokeButtons {
				position: absolute;
				height: 50px;
				width: 100%;
				bottom: 0;

				button {
					border: 1px solid rgb(184, 184, 184);
					background-color: transparent;
					border-radius: 3px;
				}

				button:hover {
					border: 1px dotted rgb(184, 184, 184);
					cursor: pointer;
				}

				#FunnyJoke {
					position: absolute;
					bottom: 0.75rem;
				}

				#NotFunnyJoke {
					position: absolute;
					width: 5.2rem;
					right: 9.8rem;
					bottom: 0.75rem;
				}

				#DismissClippyForever {
					position: absolute;
					width: 2.8rem;
					right: 1.5rem;
					bottom: 0.75rem;
				}
			}
		}

		.ClippyImage {
			position: absolute;
			right: 0;
			bottom: 0;
			height: 200px;
			-webkit-animation: rotation 0.5s infinite linear;
		}

		@keyframes rotation {
			0% {
				-webkit-transform: rotate(0deg);
			}
			25% {
				-webkit-transform: rotate(10deg);
			}
			50% {
				-webkit-transform: rotate(0deg);
			}
			75% {
				-webkit-transform: rotate(-10deg);
			}
			100% {
				-webkit-transform: rotate(0deg);
			}
		}
	}
}
