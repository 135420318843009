@import '~@studyportals/styles-abstracts/abstracts';

.LargePickerContainer {
	position: absolute;
	height: 210px;
	width: 200px;
	display: flex;
	flex-direction: column;

	background: white;
	box-shadow: 0 0 6px 0 $GreyL;
	border-radius: 5px;
	z-index: $FirstFloor;

	@include PaddingAreas(1);
	margin-top: 0.25rem;

	.PickerHeader {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;

		.YearLabel {
			@include TextStyle(Title100)
		}

		.NavButton {
			margin: auto 0;

			i {
				color: $GreyDD;
				cursor: pointer;
				font-weight: bold;
			}
		}

		.Disabled {
			i {
				color: $GreyL;
				cursor: default;
				font-weight: normal;
			}
		}
	}

	.PickerBody {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		height: 100%;
		@include TextStyle(Note);

		.MonthContainer {
			height: 35px;
			width: 62px;
			border: 1px solid $GreyL;
			border-radius: 5px;
			display: flex;
			cursor: pointer;

			.MonthLabel {

				margin: auto;
			}
		}

		& :hover {
			background: $GreyLL;
		}

		.Selected {
			background: $BrandBlue;
			border: 1px solid $BrandBlue;
			color: $White;
		}

		& :hover {
			background: $GreyLL;
			color: $GreyD;
		}

		.Disabled {
			background: rgba($GreyDD, 0.08);
			border-color: rgba($GreyDD, 0.08);
			color: rgba($GreyDD, 0.5);
			cursor: default;

			.MonthLabel:hover {
				color: rgba($GreyDD, 0.5);
				background: transparent;
			}
		}

	}
}