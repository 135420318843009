@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

#InvoiceSummary {
	@include TextStyle(Note);
	margin: 0 3rem;
	padding: 1.5rem;
	background-color: $GreyLLL;

	.InvoiceRow {
		display: flex;
		justify-content: space-between;

		.ValueCell {
			padding-right: 1rem;
		}
	}

	.InvoiceHeader {
		justify-content: start;
		padding-bottom: 0.5rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid $GreyL;

		.ValueCell {
			margin-left: 1rem;
			font-weight: bold;
		}
	}

	.Columnheader {
		margin-bottom: 0.5rem;
		color: $GreyD;
	}
}
