@import '~@studyportals/styles-abstracts/abstracts.scss';

.OptionalFiltersButtonWrapper {
	position: relative;
	margin-left: 0.6rem;
	display: inline-block;
	border-left: 1px solid $GreyL;

	.OptionalFiltersCounter {
		@include TextStyle(Note);
		position: absolute;
		right: 0.25rem;
		top: -0.5rem;
		background-color: $Grey;
		border-radius: 50%;
		color: $White;
		width: 1rem;
		text-align: center;
		vertical-align: middle;
	}
}
