@import '~@studyportals/styles-abstracts/abstracts';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.EnrolmentOverviewActionsContainer,
.ActionsContainerRow {
	.EnrolmentsTableGroupSelector {
		@include TextStyle(Body);

		.EnrolmentsGroupingOptionsLabel {
			@include MarginAreas(0, 1, 0, 0);
			color: $GreyD;
		}

		.GroupContainer {
			display: inline-block;
		}

		.GroupOption {
			@include MarginAreas(0, 1, 0, 0);
			display: inline-block;
			cursor: pointer;

			&:last-of-type {
				@include MarginAreas(0);
			}
		}

		.GroupActive {
			color: $BrandBlue;
			font-weight: 600;
		}

		&.InternalGroupSelector .GroupOption:last-of-type {
			margin-right: 0;
		}
	}
}

#ClientHistoryOverview .EnrolmentsTableGroupSelector {
	@include MarginAreas(0, 1, 1);
	display: inline-block;
}
