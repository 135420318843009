@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/variables/z-index';

.PartnershipSelector {
	position: relative;

	.PartnershipSelectorLabel {
		position: relative;
		min-height: 2.75rem;
		min-width: 12rem;

		@media all and (max-width: 1280px) {
			@include TextStyle(Note);
			min-width: 10rem;
		}
	}

	.PartnershipOptionsContainer {
		position: absolute;
		border-bottom: 1px solid $GreyL;
		z-index: $FirstFloor;
	}
}

.DropDownContainer {
	position: relative;

	.DropDownContent {
		&.Hidden {
			visibility: hidden;
		}
	}
}
