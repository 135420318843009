@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .LoadingModalWrapper.ModalWrapper.ComplexModal {
	width: 32rem;
	height: auto;

	.CloseButton {
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.LoadingModal {
	@include PaddingAreas(1, 1.5);
	text-align: center;

	.LoadingActiveText {
		@include MarginAreas(0);
		text-align: left;
	}

	.SuccessIcon,
	.FailIcon {
		@include MarginAreas(1, 0, 0);
		display: block;
		font-size: 2.5rem;
	}

	.SuccessIcon {
		color: $Green;
	}

	.FailIcon {
		color: $Red;
	}
}
