@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#ClientEnrolmentsOverview #ReviewOverview {
	.EnrolmentOverviewActionsContainer {
		@include PaddingAreas(1);
		display: flex;
		align-items: normal;
		background: $White;

		#SubmitReview {
			margin-left: auto;
		}

		#EnrolmentsTableFilter {
			margin-top: auto;
		}

		& + .EnrolmentOverviewActionsContainer {
			@include PaddingAreas(0, 1, 1);
		}
	}

	.EnrolmentsOverviewHeader {
		display: flex;
		align-items: center;
	}

	.EnrolmentsOverviewHeader {
		.PartnershipSelector {
			flex: auto;

			margin-left: 1rem;
		}
	}
}
