@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .SuspectedDuplicateDetailsModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: auto;
	max-width: calc(100% - 10rem);

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

#SuspectedDuplicateDetailsModal {
	.SuspectedDuplicateDetailsHeaderBlock {
		@include PaddingAreas(1, 1.5);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Highlight);
			@include MarginAreas(0);
			margin-bottom: 0.25rem;
			line-height: 1.25rem;
			color: $White;
			font-weight: normal;
		}
	}

	.SuspectedDuplicateDetailsContentBlock {
		max-width: 100%;
	}

	#SuspectedDuplicateDetailsTable {
		height: 100%;

		.ag-pinned-left-cols-container {
			box-shadow: 5px 0 4px -4px $GreyLL;
		}

		.ag-pinned-left-header {
			box-shadow: 2px 2px 4px -1px $GreyLL;
		}

		.ag-header-cell {
			@include TextStyle(Body);
			border-bottom: 1px solid $GreyLL;
			border-right: 1px solid $GreyLL;
			border-top: none;

			&[col-id='0'] {
				font-weight: bold;
			}
		}

		.ag-row,
		.ag-row:not(.ag-row-first) {
			@include TextStyle(Note);
			border-bottom: 1px solid $GreyLL;
			border-top: none;

			.ag-cell {
				border-right: 1px solid $GreyLL;

				&[col-id='0'] {
					font-weight: bold;
				}
			}
		}
	}
}
