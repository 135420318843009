.LogOutWidget {
	position: absolute;
	bottom: 1rem;
	right: 1rem;

	.LogOutButton {
		display: block;
	}

	.LogOutDismissButton {
		display: block;
		width: 100%;
		text-align: center;
		outline: none;
	}
}
