@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/variables/_z-index.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

$NotificationWidth: 475px;

#ReviewSubmittedNotification {
	@include MarginAreas(1, 0);
	position: relative;
	z-index: $FirstFloor;
	width: $NotificationWidth;

	.ReviewSubmittedNotificationContainer {
		@include PaddingAreas(0.5, 1);
		display: flex;
		align-items: center;
		width: 40%;
		min-width: $NotificationWidth;
		background-color: $GreenL;
		border: 1px solid $Green;
		border-radius: 1px;

		.ReviewSubmittedNotificationCheckmark {
			color: $Green;
			font-size: 24px;
			margin-right: 10px;
		}

		.ReviewSubmittedNotificationTextContainer {
			@include TextStyle(Note);

			.ReviewSubmittedNotificationText {
				margin: 5px 0;
			}
		}
	}
}
