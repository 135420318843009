@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#ClientEnrolmentsTableFilter {
	display: flex;
	flex-direction: row;

	.FilterContainer {
		display: flex;
		flex-direction: row;

		.Filter {
			margin-top: 0;

			&.Medium {
				width: 14rem;
			}
		}
	}

	@media all and (max-width: 1280px) {
		.FilterContainer .Filter.Medium {
			@include TextStyle(Note);
			width: 10.5rem;

			.MultiSelectOption,
			.MultiSelectPlaceholder,
			.MultiSelectSearchBox {
				@include TextStyle(Note);
			}
		}
	}
}
