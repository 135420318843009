.ModalOverlay .ModalWrapper.ComplexModal {
	font-family: 'Open Sans', Arial, sans-serif;
}

.RadioButton {
	margin-right: 0.5rem;

	&:disabled + label {
		color: $Grey;
	}
}

.RadioButtonLabel {
	@include TextStyle(Note);
	cursor: pointer;
}
