@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/responsive';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .ContractDetailsModalWrapper.ModalWrapper.ComplexModal {
	/* Leave room for the scroll bar. */
	width: calc(54rem + 19px);
	height: auto;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

#ContractDetailsModal {
	.ContractDetailsHeaderBlock {
		@include PaddingAreas(1, 1.5);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Highlight);
			@include MarginAreas(0);
			margin-bottom: 0.25rem;
			line-height: 1.25rem;
			color: $White;
			font-weight: normal;
		}
	}

	.ContractDetailsContentBlock {
		@include PaddingAreas(1, 1.5);
	}

	.ContractDetailsRow {
		@include MarginAreas(1, 0, 0);
		display: block;
		font-size: 0;
	}

	.ContractDetailsCell {
		@include TextStyle(Body);
		display: inline-block;
		width: 17rem;
	}

	.ContractDetailsSegmentBlock {
		@include TextStyle(Body);
		@include MarginAreas(0.5, 0, 0);
		@include PaddingAreas(1);
		border: 1px solid $GreyLL;

		.ContractDetailsSegmentTitle {
			font-size: 0;

			&.IncludedSegment {
				color: $Green;
			}

			&.ExcludedSegment {
				color: $Red;
			}

			.SegmentTypeIcon {
				@include TextStyle(Highlight);
				vertical-align: middle;
			}

			span {
				@include TextStyle(Title200);
				margin-left: 0.25rem;
				vertical-align: middle;
			}
		}

		.ContractDetailsSection {
			@include MarginAreas(1, 0, 0);

			&.IrrelevantSection {
				color: $GreyL;
			}
		}

		.ContractDetailsSubSectionTitle {
			font-size: 0;

			.SegmentSubSectionIcon {
				@include TextStyle(Body);
				vertical-align: middle;
			}

			span {
				@include TextStyle(Title100);
				@include MarginAreas(0, 0, 0, 0.5);
				vertical-align: middle;
			}
		}

		.ContractDetailsSubSectionDetails {
			@include MarginAreas(0, 0, 0, 1.5);
		}
	}
}
