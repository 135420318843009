@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import './../highlighted-feature/_highlighted-feature-positioning.scss';

#MassEditWrapper {
	@include MarginAreas(0, 0.5, 0, 0);

	.MassEditButton {
		@include PaddingAreas(0.5, 1);
	}

	.HighlightedFeatureMassEdit {
		@include positionHighlightedFeature(40rem, true, -33.1rem, false, 4.75rem);
	}
}
