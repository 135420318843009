@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.CountryIcon {
	@include MarginAreas(0, 0, 0, 1.5);
	width: 1.3rem;
	height: 1.3rem;
	border-radius: 50%;
	object-fit: cover;
	vertical-align: middle;
}

.CountryText {
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	padding-top: 14px;
}
