@import '~@studyportals/styles-abstracts/abstracts';

#EnrolmentsHistorySection {
	overflow-y: auto;

	.HistoryGroupTitleBlock {
		@include TextStyle(Highlight);
		display: flex;
		justify-content: space-between;
		position: relative;
		background-color: $White;
		border-bottom: 1px solid $GreyLLL;
		padding: 2.5rem 1.5rem;
		cursor: pointer;

		.AmountOfEnrolmentsInGroup {
			@include TextStyle(Note);
			color: $GreyD;
			border: 1px solid $Grey;
			border-radius: 0.25rem;
			padding: 0.1rem 0.5rem;
			margin-left: 0.25rem;
		}

		&.SelectedEnrolmentGroup:after {
			position: absolute;
			content: '';
			background: linear-gradient(180deg, rgba(238, 238, 238, 0.45) 0%, rgba(216, 216, 216, 0) 100%);
			width: 100%;
			height: 1rem;
			bottom: -1rem;
			left: 0;
			z-index: 1;
		}
	}
}
