@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .InvoiceBatchCreatedNotificationModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: 32rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.InvoiceBatchCreatedNotificationModal {
	.InvoiceBatchCreatedNotificationModalHeaderBlock {
		@include PaddingAreas(1);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Body);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.InvoiceBatchCreatedNotificationModalContentBlock {
		@include PaddingAreas(1);
		text-align: center;
	}

	.SuccessIcon {
		color: $Green;
	}

	.FailIcon {
		color: $Red;
	}

	.SuccessIcon,
	.FailIcon {
		@include MarginAreas(1, 0, 0);
		display: block;
		font-size: 2.5rem;
	}

	.InvoiceID {
		@include TextStyle(Title100);
		@include MarginAreas(0, 0, 0, 1);
		@include PaddingAreas(0.5, 1);
		background-color: $GreyLLL;
	}

	.InvoiceError {
		@include PaddingAreas(0.5);
		display: flex;
		justify-content: flex-start;
		background-color: $GreyLL;

		.ErrorIcon {
			@include MarginAreas(0, 0.5, 0, 0);
			font-size: 20px;
			color: $Grey;
		}

		.ErrorText {
			@include TextStyle(Body);
		}
	}

	.ButtonContainer {
		display: flex;
		justify-content: center;
		@include MarginAreas(2, 0, 0, 0);

		.PrimaryButton {
			@include MarginAreas(0, 1, 0, 0);
			color: $White;
		}
	}
}
