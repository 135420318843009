@import '~@studyportals/styles-abstracts/variables/_z-index.scss';
@import '~@studyportals/styles-abstracts/variables/_colors.scss';

@mixin WidgetWrapperBackground() {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 100vw;
	z-index: $PentHouse;
	overflow: hidden;
	backface-visibility: hidden;
	background: rgba(49, 50, 51, 0);
}

.WrapperDrawer {
	@include WidgetWrapperBackground();

	&.is-open {
		right: 0;
		.WrapperContent {
			right: 0;
			transition: right 0.4s;
		}
	}

	.WrapperContent {
		width: 46rem;
		max-width: 46rem;
		transition: right 0.4s;
		height: 100%;
		max-height: 100%;
		border-radius: 0;
		top: 0;
		right: -47rem;
		position: fixed;
		cursor: default;
		background-color: $White;
		box-shadow: 0 0 0.5rem $GreyD;
	}
}
