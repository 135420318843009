@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

.DrawerHeader {
	padding: 1rem;
	border-bottom: 1px solid $GreyLL;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.DrawerTitle {
		@include TextStyle(Highlight);
		padding-left: 1.5rem;
		margin-bottom: 0;
	}

	.ClientSyncMessage {
		@include TextStyle(Note);
		margin-right: 1rem;

		.ClientSyncIcon {
			color: $Yellow;
			font-size: 1rem;
			vertical-align: text-bottom;
		}
	}

	.DrawerControls {
		display: flex;
		align-items: center;

		.DrawerCancelButton {
			margin-left: 2rem;
			display: inline;

			.CancelIcon {
				font-size: 2rem;
				margin-bottom: 0;
			}
		}
	}
}

#EditForm {
	height: calc(100% - 73px);
	padding: 1rem;
	overflow: auto;

	.ColumnContainer {
		height: 100%;
		display: flex;
		flex-wrap: wrap;

		.Column {
			width: 50%;
			padding: 0 1.5rem;
			overflow-wrap: break-word;

			&:first-of-type {
				border-right: 1px solid $GreyLL;
			}

			.GroupWrapper {
				margin-top: 3rem;

				&:first-of-type {
					margin-top: 0;
				}

				h2 {
					@include TextStyle(Title100);
				}

				.ElementWrapper {
					margin-bottom: 1rem;
				}

				.ElementValue {
					@include TextStyle(Body);
				}
			}
		}
	}

	.EnrolmentStatusLabel {
		@include TextStyle(Note);
		background-color: $GreyLL;
		padding: 0.1rem 0.5rem;
		margin-right: 0.3rem;

		&.EnrolmentStatus {
			background-color: $GreyL;
		}
	}

	.ShowSuspectedDuplicatesButton {
		@include TextStyle(Note);
		outline: none;
	}

	.FgInput {
		width: 100%;
	}

	.InlineInput {
		display: flex;
		justify-content: space-between;

		.FgElementWrapper {
			width: 9.2rem;
		}
	}

	/* For enrolment-edit dropdowns, the floating label interferes with the regular label, so hide it. */
	.FloatingLabel.is-active {
		display: none;
	}

	.SocialMediaInput {
		width: calc(100% - 2rem - 1px);
	}

	.SocialMediaLink {
		&:link,
		&:visited,
		&:hover,
		&:active {
			color: $BrandBlue;
		}

		&:hover,
		&:active {
			text-decoration: none;
			opacity: 0.8;
		}
	}

	.SocialMediaLink,
	.DisabledSocialMediaLink {
		font-size: 1.25rem;
		padding: 0.25rem;
	}

	.DisabledSocialMediaLink {
		color: $GreyL;
	}
}
