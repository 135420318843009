@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .DuplicateAlertReasonModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: auto;
	max-width: 320px;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

#DuplicateAlertReasonModal {
	.DuplicateAlertReasonHeaderBlock {
		@include PaddingAreas(1);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Highlight);
			@include MarginAreas(0);
			margin-bottom: 0.25rem;
			line-height: 1.25rem;
			font-weight: normal;
			color: $White;
		}
	}

	.DuplicateAlertReasonContentBlock {
		@include PaddingAreas(1);

		.DuplicateAlertReasonTitle {
			@include TextStyle(Title100);
			@include MarginAreas(0, 0, 1);
		}

		.DuplicateAlertReasonButtonWrapper {
			@include MarginAreas(1, 0, 0);
			text-align: right;
		}
	}
}
