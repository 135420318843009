.Note {
	width: 100%;
	height: 8rem;
	padding: 0.5rem;
	border: 1px solid $GreyL;
	border-radius: 3px;
	resize: none;

	&::placeholder {
		color: $GreyD;
	}

	&:focus {
		border-color: $BrandBlue;
	}
}

.LabelNonEditable {
	@include TextStyle(Body);
	color: $Grey;
	display: block;
	margin-bottom: 0;

	& + .FgElementWrapper {
		margin-top: 0;
	}
}

.ContentConfirmation {
	.MessageContainer {
		.ErrorMessage {
			display: none;
		}
	}

	&.HasError .MessageContainer {
		margin-bottom: 1rem;

		.ErrorMessage {
			@include TextStyle(Note);
			display: block;
			color: $Red;
		}
	}
}
