@charset "UTF-8";
@use '~ag-grid-community/styles' as ag;

/* Import abstracts. */
@import '~@studyportals/styles-abstracts/abstracts.scss';

/* Import patterns. */
@import './page', './button', './modal', './form', './warning';

/* Vendors. */
@import '~@studyportals/form-generator/src/sass/FormGenerator.scss';
@import '~@studyportals/modal/modal.css';
@import '~@studyportals/multiselect/dist/multiselect.css';
@import '~@studyportals/monthpicker/dist/monthpicker.css';

@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-material';

@include ag.grid-styles(
	(
		theme: material,
		material-accent-color: $BrandBlue,
		material-primary-color: transparent,
		row-border-color: $GreyLL
	)
);

.ag-theme-material {
	--ag-accent-color: $BrandBlue;
	--ag-selected-color: $GreyLLL;
	--ag-border-color: $GreyLL;
	--ag-primary-color: transparent;
	--ag-grid-size: 8px;
	--ag-cell-horizontal-padding: 16px;
	--ag-header-height: 40px;
	--ag-icon-size: 12px;
	--ag-icon-color: $GreyD;
	--ag-font-family: 'Open Sans', Arial, sans-serif;
	--ag-secondary-font-family: 'Open Sans', Arial, sans-serif;
	--ag-secondary-font-weight: normal;
	--ag-tooltip-background-color: $White;
	--ag-selected-row-background-color: $GreyLLL;
	--ag-icon-none: '\E902\E910';

	.ag-row-hover,
	.ag-row-hover .TableCell,
	.ag-row-selected,
	.ag-row-selected .TableCell {
		background-color: $GreyLLL;
	}

	.ag-tooltip {
		background-color: $White;
	}
}

/* Animations. */
@keyframes SlideDown {
	0% {
		display: none;
		max-height: 0;
	}

	10% {
		display: block;
		max-height: 7vh;
	}

	40% {
		display: block;
		max-height: 28vh;
	}

	60% {
		display: block;
		max-height: 42vh;
	}

	100% {
		display: block;
		max-height: 70vh;
	}
}
@keyframes SlideUp {
	0% {
		display: none;
		max-height: 70vh;
	}

	10% {
		display: block;
		max-height: 42vh;
	}

	40% {
		display: block;
		max-height: 28vh;
	}

	60% {
		display: block;
		max-height: 7vh;
	}

	100% {
		display: block;
		max-height: 0;
	}
}

body {
	@include TextStyle(Body);
	@include MarginAreas(0);
	font-weight: 400;
	color: $GreyDD;
	background-color: $White;
	text-align: left;
	overflow-x: hidden;
}

b {
	font-weight: 700;
}

#app {
	font-family: 'Open Sans', Arial, sans-serif;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

*,
:after,
:before {
	box-sizing: border-box;
}

a,
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
	color: inherit;
	text-decoration: none;
}

.Hidden {
	display: none !important;
}
