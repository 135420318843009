@import './../../../styles/main';
@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#InternalEnrolmentsOverview {
	background-color: $GreyLLL;

	.EnrolmentsOverviewHeader {
		.Title {
			@include MarginAreas(0.5, 0, 1);
			@include TextStyle(Body);
			font-weight: 400;
		}
	}
}
