@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import './../../../styles/_page.scss';

.EnrolmentsOverview {
	#ReviewOverview .LoadingWrapper {
		@include leaveSpaceForPageElements('client', 'review', false, false, false);
	}

	&.AccommodateNotification #ReviewOverview .LoadingWrapper {
		@include leaveSpaceForPageElements('client', 'review', true, false, false);
	}

	.EnrolmentsOverviewHeader + .EnrolmentMenu + .OptionalFilterTags + .LoadingWrapper {
		@include leaveSpaceForPageElements('internal', 'review', false, false, false);
	}

	.EnrolmentsOverviewHeader + .EnrolmentMenu + .OptionalFilterTagsAvailable + .LoadingWrapper {
		@include leaveSpaceForPageElements('internal', 'review', false, true, false);
	}

	#EnrolmentsHistorySection .LoadingWrapper {
		height: 100%;
	}
}

[data-wrapper-visible='true'] + div + .FullWidthContent .EnrolmentsOverview {
	#ReviewOverview .LoadingWrapper {
		@include leaveSpaceForPageElements('client', 'review', false, false, true);
	}

	&.AccommodateNotification #ReviewOverview .LoadingWrapper {
		@include leaveSpaceForPageElements('client', 'review', true, false, true);
	}

	.EnrolmentsOverviewHeader + .EnrolmentMenu + .OptionalFilterTags + .LoadingWrapper {
		@include leaveSpaceForPageElements('internal', 'review', false, false, true);
	}

	.EnrolmentsOverviewHeader + .EnrolmentMenu + .OptionalFilterTagsAvailable + .LoadingWrapper {
		@include leaveSpaceForPageElements('internal', 'review', false, true, true);
	}
}

.LoadingWrapper {
	display: flex;
	background-color: $White;

	.LoadingAnimation {
		height: 216px;
		margin-bottom: -0.35rem;
	}

	.LoadingContent {
		position: absolute;
		width: 17rem;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: calc(50% - 6.375rem);
		text-align: center;

		.LoadingText {
			display: block;
		}
	}
}
