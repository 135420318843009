@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .EnrolmentReviewModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: 22rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.ModalOverlay .ModalWrapper.EnrolmentReviewModalWrapper {
	/* Overwrite standard modal styling so that dropdowns that flow outside the modal borders are visible. */
	overflow: visible;

	.ContentWrapper {
		overflow: visible;
	}
}

#EnrolmentReviewModal {
	.EnrolmentReviewModalHeaderBlock {
		@include PaddingAreas(1);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Body);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.EnrolmentReviewModalContentBlock {
		@include PaddingAreas(1);
		display: flex;
		flex-direction: column;
		min-height: 20rem;

		.ChallengeBackNote {
			@include MarginAreas(0, 0, 1);

			.WarningMessageIntro {
				@include MarginAreas(0, 0, 0.5);
			}

			.ChallengeBackNoteQuote {
				font-style: italic;
			}
		}
	}

	.ReviewWarningContainer {
		@include MarginAreas(1, 0, 0);
	}

	.ContentConfirmation {
		flex: 1;
	}

	.Buttons {
		@include MarginAreas(1, 0, 0);
		flex-basis: 2rem;
	}

	.RightButton {
		float: right;
	}

	.FullWidthButton {
		width: 100%;
	}

	.ContentConfirmationUpper {
		min-height: 9rem;
	}

	.MassReviewDisclaimerContentConfirmation {
		position: relative;
	}

	.MassReviewDisclaimerCheckbox {
		position: absolute;
		bottom: 0;
	}

	.MassReviewDisclaimerInput {
		margin-right: 0.75rem;
		vertical-align: middle;
	}

	.MassReviewDisclaimerLabel {
		@include MarginAreas(0);
		vertical-align: middle;
	}

	.FgForm {
		.Textarea,
		.multiselect {
			~ .MessageContainer {
				.ErrorMessage {
					display: none;
				}
			}

			&.HasError ~ .MessageContainer {
				@include MarginAreas(0, 0, 1);

				.ErrorMessage {
					@include TextStyle(Note);
					display: block;
					color: $Red;
				}
			}
		}

		.Textarea.HasError {
			border-color: $Red;
		}

		.FormInput {
			width: 100%;
		}

		.OptionalLabel {
			@include TextStyle(Note);

			color: $Grey;
		}
	}

	.AttentionRequiredMessageWrapper {
		@include PaddingAreas(1);
		@include MarginAreas(1, 0, 0);
		background-color: $GreyLL;
		font-size: 0;

		.AttentionRequiredIcon {
			@include MarginAreas(0, 0.5, 0, 0);
			color: $Grey;
			display: inline-block;
			width: 1.5rem;
			font-size: 1.25rem;
			vertical-align: middle;
		}

		.AttentionRequiredMessage {
			@include TextStyle(Note);
			display: inline-block;
			width: calc(100% - 2rem);
			vertical-align: middle;
		}

		strong {
			font-weight: 700;
		}
	}

	.MassReviewTransitionErrorMessage {
		@include TextStyle(Body);
		@include MarginAreas(0.5, 0);
		color: $Red;
	}

	.ModalConfirmationTitle {
		@include TextStyle(Title100);
	}
}
