@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .TransitionTriggersModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: 32rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

#TransitionTriggersModal {
	.TransitionTriggersModalHeaderBlock {
		@include PaddingAreas(1);
		background-color: $BrandBlue;

		h3 {
			@include TextStyle(Body);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.TransitionTriggersModalContentBlock {
		@include PaddingAreas(1);
	}

	.ConfirmationTextContainer,
	.TriggerPayloadContainer {
		@include MarginAreas(0, 0, 2);

		.TriggerPayloadForm {
			@include PaddingAreas(0.5, 0, 0);

			.TextSize {
				@include TextStyle(Note);
				color: $BrandBlue;
				text-align: right;
			}
		}

		.ReasonTitle {
			@include MarginAreas(0, 0, 0.5);
			color: $GreyD;
			display: block;
		}
	}

	.ConfirmationContentWithWarning {
		.ConfirmationTextContainer {
			@include MarginAreas(0, 0, 1);
		}

		.WarningContainer {
			@include TextStyle(Note);
			@include MarginAreas(0, 0, 1);
		}
	}

	.ChallengingBackContainer .WarningContainer {
		@include MarginAreas(1, 0);
	}

	.TransitionTriggersButtonContainer {
		display: flex;
		justify-content: center;

		.PrimaryButton {
			@include MarginAreas(0, 1, 0, 0);
		}
	}

	.InvoiceSummaryContainer {
		@include TextStyle(Body);
		@include MarginAreas(0, 0, 2);
	}

	.WarningContainer {
		@include MarginAreas(1, 0, 0);
	}

	.CheckboxList {
		@include PaddingAreas(0);
	}

	.CheckboxList,
	.Textarea {
		@include MarginAreas(0);
		box-sizing: border-box;
		list-style-type: none;

		.CheckboxListItem {
			@include MarginAreas(0);

			.DisqualificationReasonLabel {
				@include TextStyle(Body);
				@include MarginAreas(0, 0, 0, 0.5);
			}
		}

		~ .MessageContainer {
			.ErrorMessage {
				display: none;
			}
		}

		&.HasError ~ .MessageContainer {
			@include MarginAreas(0, 0, 1);

			.ErrorMessage {
				@include TextStyle(Note);
				display: block;
				color: $Red;
			}
		}
	}
}
