@import '~@studyportals/styles-abstracts/variables/_colors';
@import '~@studyportals/styles-abstracts/mixins/_typography';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ModalOverlay .EnrolmentExportModalWrapperInternal.ModalWrapper.ComplexModal,
.ModalOverlay .EnrolmentExportModalWrapperClient.ModalWrapper.ComplexModal {
	height: auto;
	width: auto;
	max-width: 28rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.ModalOverlay .EnrolmentExportModalWrapperInternal.ModalWrapper.ComplexModal {
	max-width: 36rem;
}

.EnrolmentExportModal {
	.EnrolmentExportModalHeaderBlock {
		@include PaddingAreas(1, 1.5);
		background-color: $BrandBlueD;

		h3 {
			@include TextStyle(Highlight);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.EnrolmentExportModalContentBlock {
		@include PaddingAreas(1, 1.5);
	}

	.ContentContainerExport {
		@include MarginAreas(0, 0, 1);

		.ModalExportTitle {
			@include TextStyle(Body);
		}

		.RadioButton,
		.RadioButtonLabel {
			@include TextStyle(Body);
			vertical-align: middle;
		}
	}

	.ExportPreview {
		@include TextStyle(Note);
		@include MarginAreas(0, 0, 1);
		@include PaddingAreas(1, 1, 0.5);
		background-color: $GreyLL;
		color: $GreyD;

		.InfoIcon {
			@include TextStyle(Body);
			@include MarginAreas(0, 0.5, 0, 0);
			line-height: 1rem;
		}
	}

	.ExportTypeWrapper {
		@include PaddingAreas(0.5, 0);
		display: block;

		&.LearnMoreToggled {
			@include PaddingAreas(0.5, 0, 0);
		}

		label {
			display: inline-block;
			vertical-align: middle;
		}

		.ToggleLearnMoreButton {
			@include MarginAreas(0, 0, 0, 0.5);
			text-decoration: none;
			vertical-align: middle;
			width: 7rem;
			cursor: pointer;
		}
	}

	.WarningContainer {
		@include MarginAreas(0.5, 0, 0);
		@include PaddingAreas(1);
		align-items: flex-start;

		.WarningIcon {
			margin-top: 3px;
		}

		&.WarningNoExportContent {
			@include MarginAreas(1, 0);
		}
	}
}

.ExportButtonsContainer {
	@include MarginAreas(1, 0, 0);
	display: flex;
	justify-content: space-between;

	.CancelButton {
		@include PaddingAreas(0, 2);
	}
}
