@import '~@studyportals/styles-abstracts/abstracts.scss';

.MultiSelectSearchBoxWrapper {
	position: relative;

	@include MarginAreas(0, 0.5, 0.5);
	@include PaddingAreas(0.5, 0);

	border-bottom: 1px solid $GreyL;

	.MultiSelectSearchBox {
		width: 100%;
		height: 40px;
		@include PaddingAreas(0, 0.5);
		box-sizing: border-box;

		border-radius: 5px;
		border: 1px solid $GreyD;

		@include TextStyle(Body);

		appearance: none;

		&.is-empty {
			background: $GreyLL;
			border: 0;

			&:focus {
				border: 0;
			}
		}

		&:focus {
			outline: none;
			border: 1px solid $BrandBlue;
		}

		&::placeholder {
			color: $BrandBlue;
		}
	}

	.MultiSelectSearchBoxIcon {
		position: absolute;
		right: 0.5rem;
		top: 50%;

		transform: translateY(-50%);
	}
}
