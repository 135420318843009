@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#InternalEnrolmentsTableFilter {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 69rem;

	.FilterContainer {
		@include MarginAreas(0, 1, 0, 0);

		label {
			@include TextStyle(Note);

			color: $GreyDD;
			margin: 0;
		}

		.Filter {
			margin-top: 0;

			&.Small {
				width: 12.5rem;
			}

			&.Medium {
				width: 15rem;
			}

			&.Large {
				width: 16rem;
			}
		}

		&:nth-of-type(4) {
			@include MarginAreas(0);
		}
	}

	.ClearAllFilters {
		@include MarginAreas(0, 0.5);
		@include TextStyle(Note);
		color: $BrandBlue;
		cursor: pointer;
		min-width: 5rem;
	}

	.MultiSelectWrapper .MultiSelectLabel .FloatingLabel {
		transition: none;
	}

	@media all and (max-width: 1380px) {
		min-width: 56rem;

		.FilterContainer .Filter {
			@include TextStyle(Note);

			.MultiSelectOption,
			.MultiSelectPlaceholder,
			.MultiSelectSearchBox {
				@include TextStyle(Note);
			}

			&.Small {
				width: 9.5rem;
			}

			&.Medium {
				width: 11rem;
			}

			&.Large {
				width: 13rem;
			}
		}
	}
}

#InternalHistoryOverview {
	#InternalEnrolmentsTableFilter {
		min-width: 47rem;
	}

	.ClearAllFilters {
		top: 0;
		line-height: 3rem;
	}

	.ProcessStepFilterContainer {
		display: none;
	}
}
