@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.SearchEnrolmentsBarWrapper {
	@include MarginAreas(0, 0.5, 0, 0);
	position: relative;
	display: inline-block;

	.SearchEnrolmentsButton {
		@include PaddingAreas(0.5, 1);
	}

	.SearchEnrolmentsBar {
		@include PaddingAreas(1);
		height: 3rem;
		border-radius: 3px;
		transition: width 0.2s ease-in-out;

		&.Condensed {
			width: 3rem;
		}

		&.Expanded {
			width: 12rem;
		}
	}

	.ClearEnrolmentsSearchButton {
		position: absolute;
		right: 0.5rem;
		top: 0.5rem;
		font-size: 1.5rem;
		cursor: pointer;
	}
}
