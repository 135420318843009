@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';
@import './../../../styles/_page.scss';

.EnrolmentsOverview {
	#EmptyHistoryMessage {
		@include leaveSpaceForPageElements('client', 'review', false, false, false);
	}

	&.AccommodateNotification #EmptyHistoryMessage {
		@include leaveSpaceForPageElements('client', 'review', true, false, false);
	}
}

[data-wrapper-visible='true'] + div + .FullWidthContent .EnrolmentsOverview {
	#EmptyHistoryMessage {
		@include leaveSpaceForPageElements('client', 'review', false, false, true);
	}

	&.AccommodateNotification #EmptyHistoryMessage {
		@include leaveSpaceForPageElements('client', 'review', true, false, true);
	}
}

#EmptyHistoryMessage {
	display: flex;
	padding: 5rem;
	align-items: center;
	justify-content: center;
	background-color: $White;
	color: $GreyD;

	.MessageContainer {
		text-align: center;
	}

	.EmptyImage {
		margin-bottom: 1rem;
	}
}
