@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';

#TabMenu {
	flex-grow: 1;
	text-align: right;
}

#ReviewSubmittedNotification + #TabMenu {
	margin-top: 2.5rem;
}

.SectionButton {
	@include TextStyle(Body);
	display: inline-block;
	height: 2.5rem;
	margin: 0.75rem 1rem 0;
	padding: 0 0.25rem;
	background: none;
	color: $GreyD;
	cursor: pointer;

	.SectionButtonText {
		margin-left: 0.5rem;
	}

	&:first-of-type {
		margin-left: 0;
	}

	&:hover {
		text-decoration: none;
		color: $GreyD;
		border-bottom: 0.25rem solid $GreyL;
	}

	&.Selected {
		color: $BrandBlue;
		border-bottom: 0.25rem solid $BrandBlue;
	}

	&.SettingsSection {
		margin-left: auto;

		&:hover,
		&.Active {
			border-color: transparent;
		}
	}
}
