@import '~@studyportals/styles-abstracts/variables/_z-index.scss';

#MassReviewIncentive {
	position: absolute;
	height: 0;
	width: 16rem;
	top: 8rem;
	left: 50%;
	margin-left: -7rem;
	z-index: $ThirdFloor;
	-webkit-animation: FadeIn 0.5s;
	animation: FadeIn 0.5s;

	.MassReviewIncentiveButton {
		width: 100%;

		.MassReviewIncentiveIcon {
			margin-right: 0.5rem;
		}
	}
}
