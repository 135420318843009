@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

.ColumnSavingButtonsWrapper {
	@include MarginAreas(0, 0.5, 0, 0);
	min-width: 112px;

	.ColumnSavingButton {
		@include PaddingAreas(0.5, 1);

		& + .ColumnSavingButton {
			@include MarginAreas(0, 0, 0, 0.5);
		}

		.ResetIcon {
			transform: scale(-1, 1);
		}
	}
}
